import React from 'react';
import Tabs from 'devextreme-react/tabs';
import './assets/lib/dx/dx.generic.light.css';
import './styles/ui.scss';
import logo from './assets/img/logo_botx_prac_3_1.png';
import icoDoc from './assets/img/ico-doc.png';
import './App.scss';
import {Button} from 'devextreme-react/button';
import Auth from './business-logic/auth';
import Gallery from 'devextreme-react/gallery';
import {TextBox} from "devextreme-react/text-box";
import TagBox from 'devextreme-react/tag-box';
import TileView from 'devextreme-react/tile-view';
import Ajax from "./business-logic/ajax";
import Header from "./Header";
import {withRouter} from "./business-logic/withRouter";
import DetailDescription from "./detail.description";
import DetailHowToUse from "./detail.howToUse";
import DetailReviews from "./detail.reviews";
import Footer from './Footer';

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      bot: null,
      selectedIndex: 0
    }
    this.tabs = [
      {id: 1, text: 'Description'},
      {id: 2, text: 'How To Use'},
      {id: 3, text: 'Reviews'}
    ];
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
  }

  componentDidMount() {
    Ajax.get(`Solution/Bot/${this.props.params.id}`).then(response => {
      if (response.meta) response.meta = JSON.parse(response.meta);
      this.setState({bot: response});
    });
  }

  onTabsSelectionChanged(args) {
    if (args.name === 'selectedIndex') {
      this.setState({
        selectedIndex: args.value,
      });
    }
  }

  render() {
    const {bot, selectedIndex} = this.state;
    return (
      <div className="App solution-detail dx-color-scheme-light">

        <Header></Header>

        <div className="container mt-5">
          {/* <div className="row info-wrapper mb-4">
            <div className="col-6">
              <img src={bot?.meta.img} className="responsive-img"/>
            </div>
            <div className="col-6">
              <Button icon="share" text="Share" />
              <h1>{bot?.solutionName}</h1>
              <div>
                {bot?.solutionDesc.substring(0, 512)}
              </div>
            </div>
          </div> */}
          <div className="detail-wrapper mb-4">
            <div className="breadcrumbs">
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Category Name</a></li>
              </ul>
            </div>
            <h1>{bot?.solutionName}</h1>
            <div className="detail-topbar">
              <Button icon="share" stylingMode="text" text="Share" />
            </div>
            
            <div className="row pt-4">
              <div className="col-sm-12 col-md-6">
                <div className="detail-content">

                  <img src={bot?.meta.img} className="responsive-img mb-3"/>

                  <div dangerouslySetInnerHTML={{__html: bot?.solutionDesc}}>
                  </div>

                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="detail-info">
                  <div className="detail-price">
                    <div>
                      <div className="bot-price premium">Premium</div>
                    </div>
                    <div className="bot-cta">
                      <a href="https://www.botx.cloud/get-a-proposal" target="_blank">Contact for Pricing</a>
                    </div>
                  </div>
                  <hr />
                  <div className="detail-tags mb-2">
                    <ul>
                      <li className="tag-item"><a href="">Generic</a></li>
                      <li className="tag-item"><a href="">Premium</a></li>
                      <li className="tag-item"><a href="">Template</a></li>
                    </ul>
                  </div>
                  <div className="detail-table">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td width="40%"><strong>Type</strong></td>
                          <td>Virtual Assistant, Cognitive diagram</td>
                        </tr>
                        <tr>
                          <td><strong>Last Updated</strong></td>
                          <td>November 16, 2022</td>
                        </tr>
                        <tr>
                          <td><strong>Published</strong></td>
                          <td>November 16, 2022</td>
                        </tr>
                        <tr>
                          <td><strong>Dependecies</strong></td>
                          <td>
                            <ul>
                              <li>MS Excel 2016</li>
                              <li>Adobe Acrobat Reader 2022</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Download</strong></td>
                          <td>
                            <ul>
                              <li><img src={icoDoc} /><a href="">ReadMe.txt</a></li>
                              <li><img src={icoDoc} /><a href="">How_To_Install.pdf</a></li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <div className="container mt-4">
          <div className="tabs-wrapper mb-4">
            <Tabs
              dataSource={this.tabs}
              selectedIndex={selectedIndex}
              onOptionChanged={this.onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <div className="tab-panel">
                <DetailDescription />
              </div>
            )}
            {selectedIndex === 1 && (
              <div className="tab-panel">
                <DetailHowToUse />
              </div>
            )}
            {selectedIndex === 2 && (
              <div className="tab-panel">
                <DetailReviews />
              </div>
            )}
          </div>
        </div> */}

        <Footer></Footer>

      </div>
    );
  }
}

export default withRouter(Detail);
