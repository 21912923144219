class Auth {
  // private properties
  static #currentRole = null;
  static #currentToken = null;

  // public properties
  static fetchingCurrentUser = false;
  static isMember = null;

  // class initialization
  static {
    if (localStorage.getItem('botx-role')) {
      this.#currentRole = JSON.parse(localStorage.getItem('botx-role'));
    }
    this.#currentToken = localStorage.getItem('botx-token');

    const jwtPayload = this.#getJwtPayload() || {};
    this.isMember = jwtPayload.hasOwnProperty('nameid') && !!jwtPayload['nameid'];

    // console.log('Auth static class initialized', this.#currentToken, this.#currentRole);
  }

  static #getJwtPayload() {
    const token = localStorage.getItem('botx-token');
    if (!token) return null;

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static postLoginSetup(token, role) {
    this.#currentToken = token;
    this.#currentRole = role;
    this.#currentRole.featuresJson = JSON.parse(role.featuresJson);
    localStorage.setItem('botx-token', token);
    localStorage.setItem('botx-role', JSON.stringify(this.#currentRole));

    const jwtPayload = this.#getJwtPayload();
    this.isMember = jwtPayload.hasOwnProperty('nameid') && !!jwtPayload['nameid'];
  }

  static logOut() {
    localStorage.removeItem('botx-token');
    localStorage.removeItem('botx-role');
  }

  static ifLogged() {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + (this.#currentToken || ''));
      //headers.append('Content-Type', 'application/json');
      const fetchOptions = {
        method: 'GET',
        headers: headers,
      };
      window.fetch(process.env.REACT_APP_API_URL + 'User/Check', fetchOptions)
        .then(response => {
          if (response.ok) {
            resolve(true);
          } else {
            localStorage.removeItem('botx-token');
            reject();
          }
        });
    });
  }

  static getRole(propertyName) {
    if (!this.#currentRole) return 'Logged Out';
    if (!propertyName) {
      return this.#currentRole;
    } else {
      return this.#currentRole[propertyName];
    }
  }

  static evalPermission(key) {
    if (!this.#currentRole) return false;
    return this.#currentRole.featuresJson.custom.permissionKeys.includes(key);
  }

  static getAccountBucketId() {
    return (this.#getJwtPayload() || {}).sub || '';
  }

  static getJwtClaimValue(claim) {
    return (this.#getJwtPayload() || {})[claim] || '';
  }

  static redirectToDefault(props) {
    const ai = this.evalPermission('area.ai.access');
    const dcs = this.evalPermission('area.dcs.access');
    const fs = this.evalPermission('area.fs.access');
    const as = this.evalPermission('area.as.access');
    const go = (path) => {
      if (!props) {
        window.location.href = `#${path}`;
      } else {
        props.history.push(path);
      }
    }

    // well, if there's no area permitted, then we can't do much - redirect to log in and todo: show system message
    if (!ai && !dcs && !fs && !as) {
      window.location.href = '#/account/sign-up';
      return;
    }

    if (ai) {
      go('/app/dashboard');
      return;
    }
    if (dcs) {
      go('/dcs/main-view');
      return;
    }
    // todo: the rest - fs & as
    window.location.href = '#/account/sign-up';
  }

  static redirectIfUnauthorized(permissionKey, props) {
    const result = this.evalPermission(permissionKey);
    if (!result) this.redirectToDefault(props);
    return result;
  }
}

export default Auth;
