import React from 'react';
import {withRouter} from './business-logic/withRouter';
import './assets/lib/dx/dx.generic.light.css';
import './styles/ui.scss';
import logo from './assets/img/logo_botx_prac_3_1.png';
import icoBotx from './assets/img/botx-logo-1.png';
import './App.scss';
import {Button} from 'devextreme-react/button';
import Auth from './business-logic/auth';
import Gallery from 'devextreme-react/gallery';
import {TextBox} from "devextreme-react/text-box";
import TagBox from 'devextreme-react/tag-box';
import TileView from 'devextreme-react/tile-view';
import Ajax from "./business-logic/ajax";
import Header from "./Header";
import Footer from "./Footer";

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      bots: []
    }
    this.gallery = [
      {
        name: 'Construction Cost Estimations',
        shortDesc: 'AI-powered cost estimation solution for large construction projects',
        img: 'img/gallery/construction-estimations.jpg'
      },
    ];
    this.tags = [
      {
        url: '#',
        name: 'Insurance'
      },
      {
        url: '#',
        name: 'Construction'
      },
      {
        url: '#',
        name: 'Finance'
      },
      {
        url: '#',
        name: 'Healthcare'
      },
      {
        url: '#',
        name: 'Deep Learning'
      },
      {
        url: '#',
        name: 'General MLOps'
      },
      {
        url: '#',
        name: 'Intelligent Document Processing'
      },
      {
        url: '#',
        name: 'Online Web Bots'
      },
      {
        url: '#',
        name: 'Generative Models'
      },
      {
        url: '#',
        name: 'Cloud Database'
      },
      {
        url: '#',
        name: 'Virtual Assistants'
      },
      {
        url: '#',
        name: 'Unsupervised Learning'
      }
    ];
    this.labelTags = ['Insurance', 'Construction', 'Finance', 'Healthcare', 'Deep Learning', 'General MLOps',
      'Intelligent Document Processing', 'Online Web Bots', 'Generative Models', 'Cloud Database',
      'Virtual Assistants', 'Any Industry', 'Unsupervised Learning'];
    //this.onBotClicked = this.onBotClicked.bind(this);
  }

  componentDidMount() {
    Ajax.get('Solution/Bots').then(response => {
      const withMeta = response.map(i => {
        if (!!i.meta) i.meta = JSON.parse(i.meta);
        i.shortDesc = this.inferShortDescription(i);
        i.extraShortDesc = i.shortDesc.substring(0, 70) + '..';
        return i;
      });
      this.setState({bots: withMeta});
    });
  }

  inferShortDescription(bot) {
    const toTextFn = (html) => {
      var divContainer= document.createElement("div");
      divContainer.innerHTML = html;
      divContainer.querySelectorAll('h1,h2').forEach(e => e.remove());
      return divContainer.textContent || divContainer.innerText || "";
    }
    return toTextFn(bot.solutionDesc).substring(0, 120) + '..';
  }

  galleryRender(data) {
    return (
      <div className="gallery-item">
        <img src={data.meta?.img} alt={data.solutionName} />
        {/* <div style={{backgroundImage: `url(${data.meta?.img})`}}></div> */}
        <div className="gallery-caption">
          <h2>{data.solutionName}</h2>
          <p>{data.extraShortDesc}</p>
          <a href="#">Read More</a>
        </div>
      </div>
    );
  }

  botRender(row) {
    const imgUrl = row.data.meta?.img || '';
    return (
      <div className="bot-item">
        <div className="bot-image">
          <img src={icoBotx} alt={row.data.solutionName} />
        </div>
        <div>
          <span className="bot-badge">
              Instant Installation
          </span>
        </div>
        <h2>{row.data.solutionName}</h2>
        <p>{row.data.shortDesc}</p>
        {/* <div style={{backgroundImage: `url(${imgUrl})`}}></div> */}
        {/* <div className="bot-price free">Free</div> */}
        <div className="bot-price premium">Premium</div>
      </div>
    )
  }

  accountClick() {

  }

  onBotClicked(e) {
    const seoName = e.solutionName.trim().replace(/\W+/g, '-').toLowerCase();
    this.props.navigate(`/detail/${e.id}/${seoName}`);
  }

  render() {
    const {bots} = this.state;
    return (
      <div className="App dx-color-scheme-light">

        <Header></Header>

        <div className="container mt-5">
          <div className="gallery-wrapper mb-4">
            <Gallery
              id="gallery"
              dataSource={bots.slice(2,7)}
              height="auto"
              slideshowDelay={3140}
              loop={true}
              showNavButtons={false}
              showIndicator={true}
              itemRender={this.galleryRender}/>
          </div>

          <div className="tags-wrapper mb-4">
            <ul className="tags">
              {this.tags.map(d => <li className="tag-item"><a href={d.url}>{d.name}</a></li>)}
            </ul>
            {/* <TagBox items={this.labelTags}
                    defaultValue={this.labelTags}
                    showSelectionControls={true}
                    applyValueMode="useButtons"/> */}
          </div>

          <div className="welcome-wrapper mb-3">
            <h1>BOTX Store</h1>
            <p>Welcome to BOTX Store,
              Thanks to our low code platform, it is easier than ever to design, develop and deploy to production
              AI-powered automation solutions. The BOTX Store takes this idea to the next level by allowing anyone to
              share, sell and consume existing solutions with the general public. It's like an App Store for
              robots.
            </p>
          </div>

          <div className="tiles-wrapper mb-4">
            <div className="bots-wrapper row">
              {this.state.bots.map(d => 
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="bot-item" onClick={() => this.onBotClicked(d)}>
                    <div className="bot-image">
                      <img src={icoBotx} alt={d.solutionName} />
                    </div>
                    <div>
                      <span className="bot-badge">
                          Lorem ipsum dolor
                      </span>
                    </div>
                    <h2>{d.solutionName}</h2>
                    <p>{d.shortDesc}</p>
                    {/* <div style={{backgroundImage: `url(${imgUrl})`}}></div> */}
                    {/* <div className="bot-price free">Free</div> */}
                    <div className="bot-price premium">Premium</div>
                  </div>
                </div>
              )}
            </div>
            {/* <TileView
              items={bots}
              baseItemWidth={380}
              baseItemHeight={340}
              width="100%"
              height={370*4+30}
              itemMargin={30}
              direction="vertical"
              onItemClick={this.onBotClicked}
              itemComponent={this.botRender}/> */}
          </div>
        </div>

        <Footer></Footer>

      </div>
    );
  }
}

export default withRouter(App);
