import React from 'react';
import Tabs from 'devextreme-react/tabs';
import './assets/lib/dx/dx.generic.light.css';
import './styles/ui.scss';
import logo from './assets/img/logo_botx_prac_3_1.png';
import './App.scss';
import {Button} from 'devextreme-react/button';
import Auth from './business-logic/auth';
import Gallery from 'devextreme-react/gallery';
import {TextBox} from "devextreme-react/text-box";
import TagBox from 'devextreme-react/tag-box';
import TileView from 'devextreme-react/tile-view';
import Ajax from "./business-logic/ajax";
import Header from "./Header";
import {withRouter} from "./business-logic/withRouter";

class DetailHowToUse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="">
        how to use
      </div>
    );
  }
}

export default withRouter(DetailHowToUse);
