/*
In the react-router-dom v6, the support for history has been deprecated but instead of it, navigate has been introduced.
https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
 */
import { useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        navigate={navigate}
        params={params}
        {...props}
      />
    );
  };

  return Wrapper;
};