import React from 'react';
import './assets/lib/dx/dx.generic.light.css';
import './styles/ui.scss';
import './App.scss';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    
  }

  render() {
    return (
    <footer className='App-footer'>
        <div className="container">
            <p>© Copyright 2022 BotX s.r.o. All rights reserved. <a href="#">Terms of Services</a></p>
        </div>
     </footer>
    );
  }
}

export default Footer;
