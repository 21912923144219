import React from 'react';
import './assets/lib/dx/dx.generic.light.css';
import './styles/ui.scss';
import logo from './assets/img/logo-store-1.svg';
import icoSearch from './assets/img/ico-search.png';
import icoCart from './assets/img/ico-cart.png';
import icoUser from './assets/img/ico-user.png';
import './App.scss';
import {Button} from 'devextreme-react/button';
import Auth from './business-logic/auth';
import Gallery from 'devextreme-react/gallery';
import {TextBox} from "devextreme-react/text-box";
import TagBox from 'devextreme-react/tag-box';
import TileView from 'devextreme-react/tile-view';
import Ajax from "./business-logic/ajax";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
    }
  }

  componentDidMount() {
    Auth.ifLogged().then(
      () => this.setState({logged: true}),
      () => this.setState({logged: false})
    );
  }

  searchClick() {

  }

  cartClick() {

  }

  accountClick() {

  }

  onBotClicked(e) {
    console.log('item', e);
  }

  render() {
    const {logged} = this.state;
    return (
      <header className="App-header">
        <img src={logo} className="App-logo" alt="BOTX Store" width="180"/>
        <div className="actions-wrapper">
          <Button className="mr-1"
                  icon={icoSearch}
                  text="Search"
                  stylingMode="text"
                  onClick={this.searchClick}/>
          <Button className="mr-1"
                  icon={icoCart}
                  text="Cart"
                  stylingMode="text"
                  onClick={this.cartClick}/>
          <Button icon={icoUser}
                  text={logged ? 'Account' : 'Sign-in'}
                  stylingMode="text"
                  onClick={this.accountClick}/>
        </div>
      </header>
    );
  }
}

export default Header;
