import utils from './utils.js';

// ToDo: get rid of jQuery
class Ajax {
  static getApiEndpoint(options) {
    return process.env.REACT_APP_API_URL;
  }

  static getAuthHeader() {
    return 'Bearer ' + (localStorage.getItem('botx-token') || '');
  }

  static getAuthToken() {
    return localStorage.getItem('botx-token') || '';
  }

  static shouldUseCassandraV2() {
    const CASSANDRA_API = (window.localStorage.getItem('botx-dcs-v2api') || '0') === '1';
    if (CASSANDRA_API) return true;

    const HISTORICAL_MONGO_USER = utils.hasHistoricalDCSData();
    return !HISTORICAL_MONGO_USER; // if user has historical mongodb data then we should not default to v2/cassandra
  }

  static apiVersionPrefix(url, options = undefined) {
    // user can enforce v1/v2
    // console.log('HOOK 1', url, options)
    if (!!options && options.forceNoVersion === true) return url;

    // v2 is applicable to dcs web api only
    const area = utils.getCurrentArea(true);
    const dcsForced = options && options.forcedArea === 'dcs';
    if (area !== 'dcs' && !dcsForced) return url;

    // if both of the above conditions are satisfied then we must determine the version
    if (!this.shouldUseCassandraV2()) return url;

    const parts = url.split('/');
    if (parts.length === 1) {
      parts.push('v2');
      return parts.join('/');
    } else {
      parts.splice(1, 0, 'v2');
      return parts.join('/');
    }
  }

  /**
   * Handles internally generic errors such as 401, 500 etc.
   *
   * @param {Object} jqXHR jQuery Ajax error container
   * @param {String} textStatus See http://api.jquery.com/jquery.Ajax/
   * @param {Function} customRejectCallback invokes custom error events as needed
   */
  static handleInternalError(jqXHR, textStatus, customRejectCallback) {
    if (!jqXHR || !jqXHR.status) return;

    // HTTP Status codes in this array will always do internal page redirect
    // to an appropriate error page regardless of custom error callbacks
    let critical = [401];

    // if Ajax contains own error callback handler and error is not considered
    // critical we can just give the own error callback a green light and skip
    // internal error page redirects.
    if (!~critical.indexOf(jqXHR.status) && customRejectCallback) {
      customRejectCallback(jqXHR, textStatus);
      return;
    }

    switch (jqXHR.status) {
      case 401:
        localStorage.removeItem('botx-token');
        // https://botx.cloud/#/account/login
        const currentPage = window.location.hash,
          loginHash = '#/account/login';

        // if we already are on login page - let's make an exception and call custom callback if
        // available because it likely contains some setState procedure which is likely the only
        // mechanism to signal any change
        if (currentPage === loginHash && customRejectCallback) return customRejectCallback(jqXHR, textStatus);

        window.location.href = `${loginHash}`;
        window.location.reload();
        break;
      case 404:
        window.location.href = '#/exception/404';
        break;
      case 500:
        window.location.href = '#/exception/500';
        break;
    }
  }

  /**
   * HTTP Post
   *
   * @param {String} url Resource URL
   * @param {Object} dataObj Body object to be passed to the server
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static post(url, dataObj, options = undefined) {
    const headers = options && typeof (options['headers']) !== 'undefined' ? options['headers'] : {};
    const authHeader = {'Authorization': 'Bearer ' + (localStorage.getItem('botx-token') || '')};
    return new Promise((resolve, reject) => {
      /*$.ajax({
        type: 'POST',
        url: this.getApiEndpoint(options) + this.apiVersionPrefix(url, options),
        data: JSON.stringify(dataObj),
        headers: Object.assign({}, authHeader, headers),
        contentType: 'application/json'
      }).done(responseData => {
        resolve(responseData)
      }).fail((jqXHR, textStatus) => {
        // invoke internal error handler logic
        this.handleInternalError(jqXHR, textStatus, reject);
      });*/
      resolve();
    });
  }

  /**
   * HTTP Post configured to carry file
   *
   * @param {String} url Resource URL
   * @param {Object} dataObj Non-stringified body object to be passed to the server
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static postFile(url, dataObj, options = undefined) {
    return new Promise((resolve, reject) => {
      /*$.ajax({
        type: 'POST',
        url: this.getApiEndpoint(options) + this.apiVersionPrefix(url, options),
        data: dataObj,
        headers: {'Authorization': 'Bearer ' + (localStorage.getItem('botx-token') || '')},
        contentType: false,
        processData: false
      }).done(responseData => {
        resolve(responseData)
      }).fail((jqXHR, textStatus) => {
        // invoke internal error handler logic
        this.handleInternalError(jqXHR, textStatus, reject);
      });*/
      resolve();
    });
  }

  /**
   * HTTP Put
   *
   * @param {String} url Resource URL
   * @param {Object} dataObj Non-stringified body object to be passed to the server
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static put(url, dataObj, options = undefined) {
    return new Promise((resolve, reject) => {
      /*$.ajax({
        type: 'PUT',
        url: this.getApiEndpoint(options) + this.apiVersionPrefix(url, options),
        data: JSON.stringify(dataObj),
        headers: {'Authorization': 'Bearer ' + (localStorage.getItem('botx-token') || '')},
        contentType: 'application/json'
      }).done(responseData => {
        resolve(responseData)
      }).fail((jqXHR, textStatus) => {
        // invoke internal error handler logic
        this.handleInternalError(jqXHR, textStatus, reject);
      });*/
      resolve();
    });
  }

  /**
   * HTTP Patch
   *
   * @param {String} url Resource URL
   * @param {Object} dataObj Non-stringified body object to be passed to the server
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static patch(url, dataObj, options = undefined) {
    return new Promise((resolve, reject) => {
      /*$.ajax({
        type: 'PATCH',
        url: this.getApiEndpoint(options) + this.apiVersionPrefix(url, options),
        data: JSON.stringify(dataObj),
        headers: {'Authorization': 'Bearer ' + (localStorage.getItem('botx-token') || '')},
        contentType: 'application/json'
      }).done(responseData => {
        resolve(responseData)
      }).fail((jqXHR, textStatus) => {
        // invoke internal error handler logic
        this.handleInternalError(jqXHR, textStatus, reject);
      });*/
      resolve();
    });
  }

  /**
   * HTTP Delete
   *
   * @param {String} url Resource URL
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static delete(url, options = undefined) {
    return new Promise((resolve, reject) => {
      /*$.ajax({
        type: 'DELETE',
        url: this.getApiEndpoint(options) + this.apiVersionPrefix(url, options),
        headers: {'Authorization': 'Bearer ' + (localStorage.getItem('botx-token') || '')},
        contentType: 'application/json'
      }).done(responseData => {
        resolve(responseData)
      }).fail((jqXHR, textStatus) => {
        // invoke internal error handler logic
        this.handleInternalError(jqXHR, textStatus, reject);
      });*/
      resolve();
    });
  }

  /**
   * HTTP Get
   *
   * @param {String} url Resource URL
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static get(url, options = undefined) {
    return new Promise((resolve, reject) => {

      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + (localStorage.getItem('botx-token') || ''));
      headers.append('Accept', 'application/json');
      const fetchOptions = {
        method: 'GET',
        // I.S.: Not convinced it is secure to do it. Must review:
        // for this builder.AllowCredentials() must be called in Startup.cs of websvc
        //credentials: 'include',
        headers: headers,
        mode: 'cors',
        cache: 'default',
        cf: {
          cacheEverything: true
        }
      };
      window.fetch(this.getApiEndpoint(options) + this.apiVersionPrefix(url, options), fetchOptions)
        .then(response => response.json())
        .then(responseJson => {
          resolve(responseJson);
        })
        .catch(reason => {
          this.handleInternalError(reason, '', reject);
        });
    });
  }

  /**
   * HTTP GET -> Async get an image as stream and apply into an element
   *
   * @param {String} url
   * @param {Element} img
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static getAsImage(url, img, options = undefined) {
    return new Promise((resolve) => {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + (localStorage.getItem('botx-token') || ''));
      headers.append('Accept', 'image/avif,image/webp,image/apng,image/*,*/*;q=0.8');
      const fetchOptions = {
        method: 'GET',
        // I.S.: Not convinced it is secure to do it. Must review:
        // for this builder.AllowCredentials() must be called in Startup.cs of websvc
        //credentials: 'include',
        headers: headers,
        mode: 'cors',
        cache: 'default',
        cf: {
          cacheEverything: true
        }
      };
      window.fetch(this.getApiEndpoint(options) + this.apiVersionPrefix(url, options), fetchOptions)
        .then(response => response.blob())
        .then(image => {
          img.src = URL.createObjectURL(image);
          resolve(image);
        });
    });
  }

  /**
   * HTTP GET -> Async get a file as stream and apply into an element
   *
   * @param {String} url
   * @param {Object} options Options object carrier (forcedArea: 'ai' | 'dcs')
   */
  static getAsFile(url, options = undefined) {
    return new Promise((resolve, reject) => {
      const headers = new Headers();
      headers.append('Authorization', 'Bearer ' + (localStorage.getItem('botx-token') || ''));
      const fetchOptions = {
        method: 'GET',
        // I.S.: Not convinced it is secure to do it. Must review:
        // for this builder.AllowCredentials() must be called in Startup.cs of websvc
        //credentials: 'include',
        headers: headers,
        mode: 'cors',
        cache: 'default',
        cf: {
          cacheEverything: true
        }
      };
      window.fetch(this.getApiEndpoint(options) + this.apiVersionPrefix(url, options), fetchOptions)
        .then(response => response.blob())
        .then(file => {
          const objUrl = URL.createObjectURL(file);
          resolve(objUrl);
        })
    });
  }

}

export default Ajax;
