// SI todo: please fix/clean/beautify this horrible module
import Auth from './auth';

let utils = {

  validateIPaddress: function (ipaddress) {
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
  },

  validateEmail: function (email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  },

  b64DecodeUnicode: function (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  },

  stripUnicode: function (str) {
    if (!str.normalize) return str;
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  },

  getSearchExp: function (nswh, search) {
    if (!nswh || !search) return '';

    // utils.stripUnicode(utils.b64DecodeUnicode(this.state.robotData.nswh))
    let optSearch = search.replace(/\s{2,}/g, ' ').toLowerCase(),
      decoded = this.b64DecodeUnicode(nswh),
      stripped = this.stripUnicode(decoded),
      final = stripped.toLowerCase(), //.replace(/\s+/g, ''),
      stops = final.split(','),
      words = optSearch.split(' '),
      results = words.slice(),
      result = '';

    if (stops.length === 0) return search;

    for (let i = 0; i < words.length; i++) {
      let word = stops[i];
      if (!~stops.indexOf(word)) continue;

      results[i] = '';
    }

    result = results.join(' ').trim();
    if (result === '') return search;

    return result;
  },

  secondsToHms: function (d, format, simplifiedFormat) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);

    const hFormat = !!format ? format.h : (h === 1 ? " hour, " : " hours, ");
    const mFormat = !!format ? format.m : (m === 1 ? " minute, " : " minutes, ");
    const sFormat = !!format ? format.s : (s === 1 ? " second" : " seconds");

    const hDisplay = h > 0 ? h + hFormat : "";
    const mDisplay = m > 0 ? m + mFormat : "";
    const sDisplay = (h > 0 && !!simplifiedFormat) ? "" : (s > 0 ? s + sFormat : "");

    const zeroValue = !!format ? `0${format.s}` : '0 seconds';
    return (hDisplay + mDisplay + sDisplay) || zeroValue;
  },

  formatBytes: function (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },

  getCurrentArea: function (asTitle) {
    let urlParts = window.location.hash.split('/');
    if (urlParts.length < 2) return null;
    if (asTitle === true) {
      let c = urlParts[1].toLowerCase().trim();
      if (c === 'app') return 'ai';
      if (c === 'fsm') return 'fs';
      return c;
    }
    return urlParts[1].toLowerCase().trim();
  },

  mongoObjectIdToInt: function (object_id) {
    let res = null;
    object_id = object_id.toString();
    const firstObjectId = '5661728913124370191fa3f8'
    const delta = parseInt(object_id.substring(0, 8), 16) - parseInt(firstObjectId.substring(0, 8), 16)
    res = delta.toString() + parseInt(object_id.substring(18, 24), 16).toString();
    return parseInt(res, 10);
  },

  csvToArray: function (text, delim) {
    let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
    let delimiter = delim || ',';
    for (l of text) {
      if ('"' === l) {
        if (s && l === p) row[i] += l;
        s = !s;
      } else if (delimiter === l && s) l = row[++i] = '';
      else if ('\n' === l && s) {
        if ('\r' === p) row[i] = row[i].slice(0, -1);
        row = ret[++r] = [l = ''];
        i = 0;
      } else row[i] += l;
      p = l;
    }
    return ret;
  },

  arrayToCamelCaseStr: function (arr) {
    if (!arr.length) return '';

    const setCharAt = (str, index, chr) => {
      if (index > str.length - 1) return str;
      return str.substr(0, index) + chr + str.substr(index + 1);
    };

    arr[0] = setCharAt(arr[0], 0, ('' + arr[0][0]).toLocaleLowerCase());
    for (let i = 1; i < arr.length; i++) {
      arr[i] = setCharAt(arr[i], 0, ('' + arr[i][0]).toUpperCase());
    }
    return arr.join('');
  },

  truncate: function (text, chars = 20) {
    return text.length > chars ? text.substring(0, chars) + '...' : text;
  },

  yesterday: function (n) {
    let date = new Date();
    date.setDate(date.getDate() - (n || 1));
    return date;
  },

  htmlToDiv: function (html, stripScripts) {
    let element = document.createElement('div');
    element.innerHTML = html;
    if (stripScripts) {
      Array.prototype.slice.call(element.getElementsByTagName('script')).forEach((item) => item.remove());
    }
    return element;
  },

  getQueryString: function (name) {
    const url = window.location.href;
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url);
    if (!results) {
      return undefined;
    }
    return results[1] || undefined;
  },

  hasHistoricalDCSData: function () {
    const id = parseInt(Auth.getJwtClaimValue('sub') || '0');
    if (!id) return false;
    const eligibleIds = [1, 46, 59, 67, 70, 73, 74, 77, 79, 80, 87, 88, 89, 91, 214, 216, 218, 228, 237];
    return eligibleIds.includes(id);
  },

  genRandomPass: function (pwdLen) {
    const pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    return Array(pwdLen).fill(pwdChars).map(function (x) {
      return x[Math.floor(Math.random() * x.length)]
    }).join('');
  }
}

export default utils;
